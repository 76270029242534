<template>
  <div>
    <div :id="svgId" class="svg-container"></div>
  </div>
</template>
<script>
import usaMap from '../constants/usaMap'
import MapConfig from '../constants/mapConfig'

export default {
  name: 'UsaMap',
  data: function() {
    return {
      svgId: 'usaMap',
      mapAttr: {
        viewBoxWidth: 1200,
        viewBoxHeight: 900,
        imageWidth: 540,
        imageHeight: 300,
      },
      svgContainer: null
    }
  },
  mounted: function () {
    this.generateVenueMap()
  },
  methods: {
    generateVenueMap: function () {
      const vue = this
      const mapData = usaMap.g.path
      const svgContainer = vue.$svg('usaMap').size('100%', '100%').viewbox(0, 0, vue.mapAttr.viewBoxWidth, vue.mapAttr.viewBoxHeight)
      vue.svgContainer = svgContainer;
      mapData.forEach((pathObj) => {
        vue.generatePath(svgContainer, pathObj)
      })
    },

    generatePath: function (svgCont, pathObj) {

      const isValidState = MapConfig.validStates.includes(pathObj['-title'])
      const vue = this

      const attrs = {
        'fill': isValidState ? '#149fda' : '#ccc',
        'stroke': 'white',
        'stroke-width': 2,
        'title': pathObj['-title'],
        'map-id': pathObj['-id'],
      }

      const element = svgCont.path(pathObj['-d']).attr(attrs)

      element.click(function () {
        const title = this.node.attributes['title'].value
        vue.$emit('map-clicked', {title})
      })

      element.on('mouseenter', function () {
        const title = this.node.attributes['title'].value
        vue.$emit('state-name-hover', {title})
      })

      element.on('mouseleave', function () {
        vue.$emit('state-name-off', '')
      })
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
svg path {
  cursor:pointer;
}
svg path:hover {
  fill: #0051bd;
}
</style>