export default {
  validStates: [
    'Arizona',
    'Colorado',
    'Florida',
    'Georgia',
    'Indiana',
    'Kansas',
    'Maine',
    'Michigan',
    'Minnesota',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Jersey',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Rhode Island',
    'South Carolina',
    'Tennessee',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin'
  ]
}